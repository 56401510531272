import { Box, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Container = ({ children }: Props) => {
  const bgUrl = useColorModeValue('url(/static/page-bg.png)', 'url(/static/page-bg-dark.png)');
  return (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    <Box minWidth={{ base: '100vw', lg: 'fit-content' }} backgroundImage={ bgUrl } backgroundSize="cover">
      { children }
    </Box>
  );
};

export default React.memo(Container);
