import { useColorModeValue } from '@chakra-ui/react';

export default function useCustomColors() {
  const textColor = useColorModeValue('secondary', 'primaryDark');
  const linkColor = useColorModeValue('primary', 'secondaryDark');
  const bgColor = useColorModeValue('white25', 'dark25');
  const bgColor2 = useColorModeValue('white70', 'dark75');
  const buttonBgColor = useColorModeValue('primary', 'secondaryDark');
  const buttonColor = useColorModeValue('primaryDark', 'primaryDark');
  const buttonHoverBgColor = useColorModeValue('secondaryDark', 'primary');
  const buttonBorder = useColorModeValue('', '1px solid #7A66A2');
  const shadow = useColorModeValue('0px -2px 3px #FFFFFF inset', '0px -2px 3px #00000050 inset');
  const tabBg = useColorModeValue('', 'transparent');

  return {
    bgColor,
    bgColor2,
    textColor,
    linkColor,
    buttonBgColor,
    buttonColor,
    buttonHoverBgColor,
    buttonBorder,
    shadow,
    tabBg,
  };
}
